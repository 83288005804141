import React from 'react';

import { SvgIcon, SvgIconProps } from '@material-ui/core';

const IconSymfony = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g id="Groupe_2960" data-name="Groupe 2960" transform="translate(0)">
        <g id="compacte" transform="translate(0 0)">
          <g
            id="Groupe_2959"
            data-name="Groupe 2959"
            transform="translate(0 0)"
          >
            <path
              id="Tracé_6431"
              data-name="Tracé 6431"
              d="M57.679,30.636A25.48,25.48,0,1,0,83.161,56.114,25.479,25.479,0,0,0,57.679,30.636ZM71.42,45.382a1.794,1.794,0,0,1-2.032-1.734,1.824,1.824,0,0,1,.359-1.137c.263-.515.319-.573.312-.8-.023-.674-1.042-.7-1.321-.687-3.825.126-4.833,5.289-5.65,9.486l-.4,2.213a6.853,6.853,0,0,0,4.638-.639c1.228-.8-.343-1.615-.146-2.522a1.8,1.8,0,0,1,1.711-1.389A1.763,1.763,0,0,1,70.472,50.1c-.034,1.626-2.194,3.863-6.511,3.772-.526-.013-1.011-.05-1.462-.1l-.814,4.5c-.73,3.406-1.7,8.059-5.166,12.118-2.979,3.54-6,4.091-7.355,4.137-2.532.085-4.211-1.264-4.271-3.066a2.692,2.692,0,0,1,2.5-2.732,2.161,2.161,0,0,1,2.322,2.063,1.367,1.367,0,0,1-.793,1.434c-.221.176-.553.358-.54.752a.656.656,0,0,0,.751.536,3.981,3.981,0,0,0,2.278-.919c2.466-2.055,3.417-5.638,4.659-12.159l.26-1.581c.424-2.121.894-4.486,1.612-6.841-1.74-1.309-2.782-2.932-5.12-3.566-1.6-.436-2.581-.066-3.268.8a2.368,2.368,0,0,0,.244,3.154l1.3,1.435c1.592,1.842,2.464,3.273,2.136,5.2-.518,3.075-4.184,5.434-8.514,4.1-3.7-1.139-4.389-3.758-3.945-5.2a1.736,1.736,0,0,1,2.387-1.205,2.07,2.07,0,0,1,1.167,2.6,4.016,4.016,0,0,1-.2.516,7.042,7.042,0,0,0-.448.828c-.238.773.824,1.323,1.564,1.55a2.89,2.89,0,0,0,3.676-1.695,2.379,2.379,0,0,0-.721-2.419L46.63,56.432c-.717-.8-2.294-3.023-1.524-5.523a5.328,5.328,0,0,1,1.826-2.661A6.587,6.587,0,0,1,52.9,47.158C55.467,47.9,56.7,49.591,58.3,50.9a21.985,21.985,0,0,1,3.994-7.35,9.134,9.134,0,0,1,6.531-3.49c2.587-.084,4.541,1.087,4.606,2.942A2.2,2.2,0,0,1,71.42,45.382Z"
              transform="translate(-32.2 -30.636)"
              fill="#fff"
            />
            <g
              id="Groupe_2958"
              data-name="Groupe 2958"
              transform="translate(64.29 9.461)"
            >
              <g
                id="Groupe_2957"
                data-name="Groupe 2957"
                transform="translate(80.35 8.848)"
              >
                <path
                  id="Tracé_6432"
                  data-name="Tracé 6432"
                  d="M199.748,50.6c5.874,0,9.814,4.245,9.814,10.118,0,5.534-4.016,10.118-9.814,10.118A9.774,9.774,0,0,1,189.9,60.716C189.9,54.844,193.835,50.6,199.748,50.6Zm0,17.358c4.168,0,6.023-3.791,6.023-7.241,0-3.674-2.235-7.237-6.023-7.237-3.827,0-6.062,3.562-6.062,7.237C193.685,64.166,195.541,67.956,199.748,67.956Z"
                  transform="translate(-189.896 -50.598)"
                  fill="#fff"
                />
              </g>
              <path
                id="Tracé_6433"
                data-name="Tracé 6433"
                d="M188.169,51.6V50.254h-5.116V48.416c0-2.614.378-4.586,3.448-4.586.057,0,.116,0,.173.006.006,0,.005-.009.014-.009a1.54,1.54,0,0,0,1.609-1.482l.061-1.167a14.6,14.6,0,0,0-2.387-.227c-5.268,0-6.481,3.07-6.481,7.769v1.535h-4.548v1.5a1.612,1.612,0,0,0,1.594,1.376s.007,0,.012,0h2.942V69.582h1.968a1.616,1.616,0,0,0,1.594-1.4V53.135h3.562A1.611,1.611,0,0,0,188.169,51.6Z"
                transform="translate(-108.308 -40.951)"
                fill="#fff"
              />
              <path
                id="Tracé_6434"
                data-name="Tracé 6434"
                d="M136.2,51.094c-.007,0-.009,0-.017,0a2.058,2.058,0,0,0-1.795,1.213l-4.9,14.775h-.076l-4.8-14.769a2.057,2.057,0,0,0-1.8-1.221c-.008,0-.012,0-.019,0h-2.484l6.555,18.039a10.672,10.672,0,0,1,.683,2.046c0,.378-1.061,4.773-4.245,4.773-.077,0-.159-.009-.237-.014a1.5,1.5,0,0,0-1.562,1.4l-.1,1.233a11.7,11.7,0,0,0,2.428.266c4.7,0,6.1-4.283,7.427-8l7.125-19.745H136.2Z"
                transform="translate(-103.785 -41.791)"
                fill="#fff"
              />
              <path
                id="Tracé_6435"
                data-name="Tracé 6435"
                d="M112.062,54.248c-2.942-1.506-6.16-2.528-6.217-5.556.009-3.217,2.964-4.061,5.243-4.058a.086.086,0,0,1,.024,0,11.48,11.48,0,0,1,2.553.268c.009,0,.008-.012.022-.012a1.534,1.534,0,0,0,1.6-1.383l.065-1.168a18.846,18.846,0,0,0-4.412-.559c-4.953.03-8.63,2.522-8.643,7.277.008,4.157,2.81,5.769,5.822,7.228,2.956,1.421,6.195,2.594,6.221,6.025-.017,3.579-3.473,4.9-5.853,4.9a14.671,14.671,0,0,1-4.176-.753c-.8-.131-1.454.572-1.556,1.505l-.108,1.042a17.639,17.639,0,0,0,5.349,1.059h.024c5.545-.04,9.857-2.254,9.875-8C117.883,57.633,115.061,55.772,112.062,54.248Z"
                transform="translate(-102.293 -41.019)"
                fill="#fff"
              />
              <path
                id="Tracé_6436"
                data-name="Tracé 6436"
                d="M169.323,70.379h0a1.615,1.615,0,0,0,1.574-1.261V57.989c0-4.206-1.779-7.391-6.709-7.391a6.89,6.89,0,0,0-5.984,3.752,5.712,5.712,0,0,0-5.495-3.752,6.526,6.526,0,0,0-6.137,3.26H146.5v-1.2a1.616,1.616,0,0,0-1.614-1.6c-.007,0-.011,0-.017,0h-1.7V70.379H145.1a1.617,1.617,0,0,0,1.617-1.616.118.118,0,0,1,.013-.05V60.641c0-3.6,1.439-7.163,5.077-7.163,2.88,0,3.451,2.993,3.451,5.38v11.52h1.962a1.61,1.61,0,0,0,1.6-1.471V60.641c0-3.6,1.441-7.163,5.078-7.163,2.88,0,3.45,2.993,3.45,5.38v11.52h1.982Z"
                transform="translate(-105.677 -41.75)"
                fill="#fff"
              />
              <path
                id="Tracé_6437"
                data-name="Tracé 6437"
                d="M230.3,70.379h.006a1.612,1.612,0,0,0,1.608-1.543V58.9c0-5.19-2.237-8.3-7.24-8.3a6.952,6.952,0,0,0-6.329,3.526h-.074V52.68s0,0,0-.006a1.616,1.616,0,0,0-1.615-1.618c-.009,0-.012,0-.019,0h-1.814V70.379h1.964a1.609,1.609,0,0,0,1.6-1.488V61.437c0-4.7,1.821-7.958,5.874-7.958,3.11.189,4.092,2.386,4.092,6.9v10H230.3Z"
                transform="translate(-111.609 -41.75)"
                fill="#fff"
              />
              <path
                id="Tracé_6438"
                data-name="Tracé 6438"
                d="M251.171,51.094c-.006,0-.009,0-.019,0a2.054,2.054,0,0,0-1.793,1.213l-4.9,14.775h-.078l-4.8-14.769a2.06,2.06,0,0,0-1.8-1.221c-.005,0-.011,0-.017,0h-2.484l6.555,18.039a10.7,10.7,0,0,1,.685,2.046c0,.378-1.065,4.773-4.248,4.773-.08,0-.158-.009-.238-.014a1.5,1.5,0,0,0-1.56,1.4l-.1,1.233a11.7,11.7,0,0,0,2.427.266c4.7,0,6.1-4.283,7.429-8l7.125-19.745h-2.192Z"
                transform="translate(-113.303 -41.791)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default IconSymfony;
