import React from 'react';

import { SvgIcon, SvgIconProps } from '@material-ui/core';

const IconDrupal = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g
        id="Groupe_2977"
        data-name="Groupe 2977"
        transform="translate(0 0.001)"
      >
        <g
          id="Groupe_2976"
          data-name="Groupe 2976"
          transform="translate(0 -0.001)"
        >
          <path
            id="Tracé_6513"
            data-name="Tracé 6513"
            d="M272.8,771.363a19.656,19.656,0,0,1-5.764-6.913,21.944,21.944,0,0,1-2.613-11.7,18.75,18.75,0,0,1,3.456-9.419,19.905,19.905,0,0,1,7.99-6.512,30.678,30.678,0,0,0,5.47-3.328,7.541,7.541,0,0,0,3.1-5.689c.063-1.145.172-2.288.261-3.439.3-.009.427.225.578.382,2.15,2.208,4.418,4.292,6.719,6.34a7.9,7.9,0,0,1,.6.671,5.835,5.835,0,0,1,.663.626c.112.133.336.23.227.463-.1.208-.307.2-.49.189-.779-.043-1.5.3-2.274.31a.312.312,0,0,0-.229.147,13.883,13.883,0,0,0-3.294,1.554,10.675,10.675,0,0,0-3.235,14.345c.3.532.351.747-.355.875a13.261,13.261,0,0,0-8.782,5.391c-3.408,4.782-3.709,9.849-1.024,15.082.053.1.092.213.138.319.094.136.1.312.2.445.128.181.258.366.013.537-.216.151-.414.024-.554-.133A2.346,2.346,0,0,0,272.8,771.363Z"
            transform="translate(-264.376 -724.364)"
            fill="#00a7e1"
          />
          <path
            id="Tracé_6514"
            data-name="Tracé 6514"
            d="M350.576,786.579a11.44,11.44,0,0,0,4.961-3.7,11.784,11.784,0,0,0,2.1-5.034c.324-.615.086-1.276.157-1.911.033-.293-.03-.636.427-.641h0a6.531,6.531,0,0,0,1.158,1.411,19.114,19.114,0,0,1,4.035,7.619,17.24,17.24,0,0,1-.727,9.724,21.789,21.789,0,0,1-9.944,11.908,36.739,36.739,0,0,1-3.7,1.593c-.261.074-.531.162-.434-.293a16.549,16.549,0,0,0,3.5-4.537,13.7,13.7,0,0,0-1.454-14.757.985.985,0,0,1-.086-.19C350.406,787.371,349.955,786.974,350.576,786.579Z"
            transform="translate(-318.113 -756.858)"
            fill="#00a7e1"
          />
          <path
            id="Tracé_6515"
            data-name="Tracé 6515"
            d="M301.237,819.075a8.214,8.214,0,1,1,8.122,8.212A8.188,8.188,0,0,1,301.237,819.075Z"
            transform="translate(-287.895 -779.536)"
            fill="#00a7e1"
          />
          <path
            id="Tracé_6516"
            data-name="Tracé 6516"
            d="M332.616,761.329a6.268,6.268,0,1,1-6.284,6.271A6.308,6.308,0,0,1,332.616,761.329Z"
            transform="translate(-303.907 -747.95)"
            fill="#00a7e1"
          />
          <path
            id="Tracé_6517"
            data-name="Tracé 6517"
            d="M336.522,746.524c.035-.406.352-.307.587-.308.782,0,1.475-.515,2.345-.228-.187-.609-.728-.742-.83-1.2.7.251,1.08.893,1.645,1.365A32.777,32.777,0,0,0,336.522,746.524Z"
            transform="translate(-310.409 -737.397)"
            fill="#00a7e1"
          />
          <path
            id="Tracé_6518"
            data-name="Tracé 6518"
            d="M287.637,853.68c.376-.081.6.16.828.391.127.128.267.318.458.175.21-.157-.062-.261-.1-.4a3,3,0,0,1-.048-.476l1.261,1.752-.124.137A9.061,9.061,0,0,1,287.637,853.68Z"
            transform="translate(-279.218 -806.681)"
            fill="#00a7e1"
          />
          <path
            id="Tracé_6519"
            data-name="Tracé 6519"
            d="M374,773.951c-.566.533-.159,1.219-.286,1.821-.055.262.161.635-.3.732a26.566,26.566,0,0,0-.093-3.314C373.688,773.472,373.928,773.634,374,773.951Z"
            transform="translate(-333.892 -755.517)"
            fill="#00a7e1"
          />
          <path
            id="Tracé_6520"
            data-name="Tracé 6520"
            d="M352.76,806.479c-.334.4.071.792,0,1.188a4.256,4.256,0,0,1-.343-.327c-.157-.185-.471-.362-.382-.6C352.134,806.482,352.482,806.483,352.76,806.479Z"
            transform="translate(-320.297 -776.758)"
            fill="#00a7e1"
          />
          <path
            id="Tracé_6521"
            data-name="Tracé 6521"
            d="M376.35,776.7a1.949,1.949,0,0,1-1.158-1.411A4.443,4.443,0,0,1,376.35,776.7Z"
            transform="translate(-335.084 -756.858)"
            fill="#00a7e1"
          />
          <path
            id="Tracé_6522"
            data-name="Tracé 6522"
            d="M347.351,863.595c0,.312.253.249.434.293a1.625,1.625,0,0,1-1.171.251C346.848,863.852,347.005,863.6,347.351,863.595Z"
            transform="translate(-316.849 -813.202)"
            fill="#00a7e1"
          />
        </g>
        <path
          id="Tracé_6523"
          data-name="Tracé 6523"
          d="M589.536,777.853h-6.372l.01,11.922c0,3.484-1.494,6.3-4.978,6.3s-4.993-2.818-4.993-6.3V777.864h-6.355l0,11.911c0,6.885,4.426,12.464,11.311,12.464s11.376-5.58,11.376-12.464Z"
          transform="translate(-457.37 -758.494)"
          fill="#00a7e1"
        />
        <rect
          id="Rectangle_1901"
          data-name="Rectangle 1901"
          width="6.34"
          height="31.036"
          transform="translate(189.62 12.094)"
          fill="#00a7e1"
        />
        <path
          id="Tracé_6524"
          data-name="Tracé 6524"
          d="M818.726,758.978v-1.115h4.941v1.115H821.86v4.914h-1.325v-4.914Z"
          transform="translate(-618.084 -745.74)"
          fill="#00a7e1"
        />
        <path
          id="Tracé_6525"
          data-name="Tracé 6525"
          d="M836.157,757.864l1.411,4.146h.017l1.333-4.146h1.868v6.029h-1.242V759.62h-.017l-1.478,4.273h-1.022l-1.477-4.231h-.017v4.231h-1.241v-6.029Z"
          transform="translate(-628.015 -745.74)"
          fill="#00a7e1"
        />
        <path
          id="Tracé_6526"
          data-name="Tracé 6526"
          d="M524.41,776.8c-5.465-1.262-8.913,4.168-9.057,4.459-.07.143-.073.226-.315.22-.2,0-.223-.22-.223-.22l-.679-4.154h-5.68v23.7h6.368V787.982c0-1.048,2.823-6.075,8.293-4.782a8.642,8.642,0,0,1,3.94,1.829v-7.221a10.155,10.155,0,0,0-2.649-1.005"
          transform="translate(-420.114 -757.703)"
          fill="#00a7e1"
        />
        <path
          id="Tracé_6527"
          data-name="Tracé 6527"
          d="M648.322,782.8a6.353,6.353,0,1,1-6.352,6.354v0a6.354,6.354,0,0,1,6.352-6.352m-6.171,25.812v-8.529l0,0v-3.195s.01-.255.24-.258c.206,0,.252.133.3.258a7.551,7.551,0,0,0,9.029,4.343,12.551,12.551,0,1,0-15.958-12.081v19.46Z"
          transform="translate(-501.345 -757.694)"
          fill="#00a7e1"
        />
        <path
          id="Tracé_6528"
          data-name="Tracé 6528"
          d="M730.713,789.158a6.353,6.353,0,1,1-6.355-6.352h0a6.354,6.354,0,0,1,6.353,6.352m-.181,11.663h6.384V789.158a12.55,12.55,0,1,0-15.958,12.08,7.55,7.55,0,0,0,9.029-4.342c.05-.125.1-.261.3-.259.23,0,.24.259.24.259"
          transform="translate(-549.868 -757.7)"
          fill="#00a7e1"
        />
        <path
          id="Tracé_6529"
          data-name="Tracé 6529"
          d="M442.315,763.119h-2.56v20.24l2.629.068c5.392,0,8.864-.49,8.864-10.186,0-9.3-3.065-10.122-8.933-10.122m-1.729,25.618h-7.243V757.747h7.771c9.409,0,16.546,1.726,16.546,15.494,0,13.636-7.56,15.5-17.075,15.5"
          transform="translate(-372.188 -745.665)"
          fill="#00a7e1"
        />
      </g>
    </SvgIcon>
  );
};

export default IconDrupal;
