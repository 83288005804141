import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const IconAngular = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g id="angular-seeklogo.com" transform="translate(0.001)">
        <g
          id="Groupe_2965"
          data-name="Groupe 2965"
          transform="translate(-0.001)"
        >
          <g
            id="Groupe_2963"
            data-name="Groupe 2963"
            transform="translate(55.281 11.563)"
          >
            <path
              id="Tracé_6439"
              data-name="Tracé 6439"
              d="M.6,31.926V8.8H3.573L17.706,27.411V8.8h2.826V31.926H17.559L3.426,13.131V31.926Z"
              transform="translate(-0.6 -5.753)"
              fill="#dd0031"
              fillRule="evenodd"
            />
            <path
              id="Tracé_6440"
              data-name="Tracé 6440"
              d="M85.574,30.691a17.163,17.163,0,0,1-6.02,1.065Q67,31.756,67,19.716,67,8.318,79.113,8.3a21.468,21.468,0,0,1,6.461.954v2.57a16.8,16.8,0,0,0-6.13-1.138c-6.314,0-9.471,3.01-9.471,8.957,0,6.424,3.12,9.617,9.324,9.617a16.163,16.163,0,0,0,3.3-.4V21.111h2.973v9.581Z"
              transform="translate(-42.626 -5.437)"
              fill="#dd0031"
              fillRule="evenodd"
            />
            <path
              id="Tracé_6441"
              data-name="Tracé 6441"
              d="M130.6,23.006V8.8h2.973V23.006c0,4.442,2.2,6.681,6.644,6.681,4.4,0,6.644-2.239,6.644-6.681V8.8h2.973V23.006c0,6.057-3.194,9.1-9.617,9.1s-9.617-3.047-9.617-9.1Z"
              transform="translate(-82.88 -5.753)"
              fill="#dd0031"
              fillRule="evenodd"
            />
            <path
              id="Tracé_6442"
              data-name="Tracé 6442"
              d="M200.473,8.8V29.5h12.113v2.423H197.5V8.8Z"
              transform="translate(-125.223 -5.753)"
              fill="#dd0031"
              fillRule="evenodd"
            />
            <path
              id="Tracé_6443"
              data-name="Tracé 6443"
              d="M244.92,26.672H241.8L253.289.5l11.489,26.172h-3.3L258.5,19.4h-7.635l.808-2.423h5.837L253.179,6.52,244.92,26.672Z"
              transform="translate(-153.262 -0.5)"
              fill="#dd0031"
              fillRule="evenodd"
            />
            <path
              id="Tracé_6444"
              data-name="Tracé 6444"
              d="M313.1,31.926V8.8h9.874c4.4,0,6.607,1.835,6.607,5.543,0,3.01-2.166,5.249-6.461,6.681l7.929,10.9h-3.928l-7.342-10.388V19.592c4.4-.7,6.644-2.386,6.644-5.1,0-2.129-1.211-3.194-3.671-3.194h-6.607V31.926Z"
              transform="translate(-198.389 -5.753)"
              fill="#dd0031"
              fillRule="evenodd"
            />
          </g>
          <g id="Groupe_2964" data-name="Groupe 2964">
            <path
              id="Tracé_6445"
              data-name="Tracé 6445"
              d="M0,8.443,23.676,0l24.3,8.3L44.049,39.607,23.676,50.876,3.634,39.754,0,8.443Z"
              fill="#dd0031"
              fillRule="evenodd"
            />
            <path
              id="Tracé_6446"
              data-name="Tracé 6446"
              d="M88.8,8.3,64.5,0V50.876L84.873,39.607,88.8,8.3Z"
              transform="translate(-40.824)"
              fill="#c3002f"
              fillRule="evenodd"
            />
            <path
              id="Tracé_6447"
              data-name="Tracé 6447"
              d="M39.156,16.2h0L24.4,49.016l5.506-.11,2.973-7.415H46.057l3.23,7.488,5.249.11L39.156,16.2Zm.037,10.535L44.185,37.16h-9.36l4.368-10.425Z"
              transform="translate(-15.443 -10.253)"
              fill="#fff"
              fillRule="evenodd"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default IconAngular;
